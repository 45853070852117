<template>
  <div class="the-home">
    <div class="container">
      <b-row>
        <b-col class="text-center" cols="12">
          <h1>แบบประเมินว่ากิจการควรจดบริษัทรูปแบบ</h1>
          <h2 :class="maincolor">
            “บริษัทจำกัด” “ห้างหุ้นส่วนจำกัด” หรือ
            “ห้างหุ้นส่วนสามัญนิติบุคคล”
          </h2>
          <img
            class="img"
            src="../assets/head-line.png"
            alt="Store Images"
            style="padding-bottom: 30px;"
          />
        </b-col>
      </b-row>
      <b-row>
       <ValidationObserver v-slot="{ handleSubmit, valid }">
          <b-col>
            <TheAssessmentFormCard :questions="questions" />
          </b-col>

          <b-col class="text-center padding" cols="12">
            <b-button
              variant="warning"
              @click="handleSubmit(submit)"
              type="submit"
              :class="{ 'btn-disabled': !valid }"
            >
              <img
                class="img"
                src="../assets/icon-view.png"
                alt="Store Images"
                height="25px"
                width="30px"
              />
              ประมวลผล
            </b-button>
            &nbsp;&nbsp;&nbsp;
            <b-button variant="secondary" @click="refreshComponent"
              >ล้างข้อมูล</b-button
            >
          </b-col>
        </ValidationObserver>
      </b-row>
      <b-row></b-row>
    </div>
  </div>
</template>

<script>
import TheAssessmentFormCard from "./TheAssessmentFormCard.vue";
// import { ValidationObserver, ValidationProvider } from 'vee-validate';
export default {
  name: "TheHome",
  components: {
    TheAssessmentFormCard,
  },
  data() {
    return {
      questions: [
        {
          title:
            "1.กิจการของคุณมีทุนจดทะเบียนน้อยกว่าความรับผิดชอบทรัพย์สินต่างๆ ที่เกี่ยวข้องกับการดำเนินกิจการ (เช่น ธุรกิจขนส่งที่มีการส่งสินค้าที่สินค้ามีมูลค่าสูงกว่าทุนจดทะเบียน)",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "2.กิจการของคุณต้องการความน่าเชื่อถือเพื่อใช้ในการดำเนินกิจการ",
          isTrue: null,
          ishigRisk: true,
        },
        {
        title:
        "3.ผู้ถือหุ้นทั้งหมดของกิจการ มีความประสงค์รับผิดชอบในหนี้สินกิจการเฉพาะเงินลงทุนของตนเอง",
        isTrue: null,
        ishighRisk: true,
        },
        {
        title:
        "4.กิจการของคุณ มีผู้ถือหุ้นทั้งแบบรับผิดชอบในหนี้สินทั้งหมด (หุ้นส่วนผู้จัดการ มีสิทธิ์ตัดสินใจในกิจการ) และผู้ถือหุ้นบางคนรับผิดชอบหนี้สินเฉพาะเงินที่ลงทุน (ไม่มีสิทธิ์ตัดสินจในกิจการ)",
        isTrue: null,
        ishighRisk: true,
        },
        {
        title:
        "5.ผู้ถือหุ้นทั้งหมดของกิจการ มีความประสงร่วมกันรับผิดชอบในหนี้สินของกิจการทั้งหมด (คือ ผู้ถือหุ้นทุกคนสามารถมีสิทธิ์บริหารจัดการกิจการได้)",
        isTrue: null,
        ishighRisk: true,
        },
        ],
        maincolor: "maincolor",
        };
        },
  methods: {
    submit() {
    const answeredAllQuestions = this.questions.slice(2, 6).every((question) => question.isTrue !== null);
    if (answeredAllQuestions) {
    const selectedQuestions = this.questions.slice(2, 6).filter((question) => question.isTrue === true);
    if (selectedQuestions.length > 1) {
      alert("โปรดเลือกคำตอบใช่เพียงหนึ่งข้อจากคำถามที่ 3, 4, และ 5");
    } else if (selectedQuestions.length === 0) {
      alert("โปรดเลือกคำตอบใช่เพียงหนึ่งข้อจากคำถามที่ 3, 4, และ 5");
    } else {
      const selectedQuestion = selectedQuestions[0];
      if (selectedQuestion.title === "3.ผู้ถือหุ้นทั้งหมดของกิจการ มีความประสงค์รับผิดชอบในหนี้สินกิจการเฉพาะเงินลงทุนของตนเอง") {
        this.$router.push("/LimitedView").catch(() => {});
      } else if (selectedQuestion.title === "4.กิจการของคุณ มีผู้ถือหุ้นทั้งแบบรับผิดชอบในหนี้สินทั้งหมด (หุ้นส่วนผู้จัดการ มีสิทธิ์ตัดสินใจในกิจการ) และผู้ถือหุ้นบางคนรับผิดชอบหนี้สินเฉพาะเงินที่ลงทุน (ไม่มีสิทธิ์ตัดสินจในกิจการ)") {
        this.$router.push("/PartnershipView").catch(() => {});
      } else if (selectedQuestion.title === "5.ผู้ถือหุ้นทั้งหมดของกิจการ มีความประสงร่วมกันรับผิดชอบในหนี้สินของกิจการทั้งหมด (คือ ผู้ถือหุ้นทุกคนสามารถมีสิทธิ์บริหารจัดการกิจการได้)") {
        this.$router.push("/OrdinaryView").catch(() => {});
      }
    }
  } else {
    alert("โปรดตอบคำถามทั้งหมดก่อนส่งแบบฟอร์ม");
  }
}, 
  refreshComponent() {
    this.$router.go();
  },
  },
  };
</script>

<style scoped>
.maincolor {
  color:#EA6B0D;
}
.padding {
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.img {
  padding-right: 10px;
}
.btn-warning{
  color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
}
</style>