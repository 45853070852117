
<template>
  <div class="the-home">
    <div class="container">
      <b-row >
        <b-col class="text-center" cols="12">
          <h1>ผลประเมินของคุณ!!</h1>
          <img class="img" src="../assets/head-line.png" alt="Store Images" style="padding-bottom: 30px;"/>
      </b-col>
      </b-row>

    <b-card style="box-shadow: 1px 1px 12px 5px darkgrey; border: 0px solid rgba(0, 0, 0, 0.125);">
      <img class="img" src="../assets/risk.png" alt="Store Images"/>&nbsp;&nbsp;&nbsp;
      <b-button variant="warning">กิจการของคุณเหมาะจดทะเบียนบริษัทรูปแบบ “ห้างหุ้นส่วนจำกัด” </b-button>  
      <!-- <b-button>คุณอยู่ในระดับ “ความเสี่ยงสูง”</b-button> -->
   <br> <br>
      <h2>โดยมีเงื่อนไขดังนี้</h2>
     
     <li>
      ต้องมีผู้ถือหุ้นตั้งแต่ 2 คนขึ้นไป
     </li>
     <li>
      ไม่มีกำหนดทุนขั้นต่ำ
     </li>
     <li>
      ผู้ถือหุ้นมี 2 แบบคือ
      <br>
      1) “จำกัด” ความรับผิด คือรับผิดชอบตามเงินลงทุนของตนเอง แต่จะไม่มีสิทธิ์ตัดสินใจในกิจการ มีสิทธิ์เพียงสอบถามการดำเนินงานของกิจการได้ <br>
      2) “ไม่จำกัด” ความรับผิด คือรับผิดชอบหนี้สินทั้งหมดที่เกิดขึ้นจากกิจการ โดยมีสิทธิ์ตัดสินใจต่างๆ ในกิจการได้อย่างเต็มที่ (หุ้นส่วนผู้จัดการจะต้องเป็นหุ้นส่วนประเภทไม่จำกัดความรับผิด)
     </li>

<br>
<h6 :class="maincolor">หลังจากได้คำตอบแล้วว่ากิจการของคุณเหมาะจดบริษัทรูปแบบไหน ก่อนจดบริษัทกิจการจำเป็นต้องมีผู้สอบบัญชีเพื่อขอจดบริษัทด้วย ซึ่งสามารถศึกษาแนวทางการเลือกผู้สอบบัญชีได้
 <a href="https://inflowaccount.co.th/types-of-auditors/">ที่นี่ </a> 
  


</h6>

      <h6 :class="maincolor">หากต้องการคำแนะนำวางแผนเรื่องภาษีสามารถทักแชทได้ที่ <a :class="maincolor" href="https://lin.ee/911BqsX" target="_blank" rel="noopener noreferrer">@ac567</a>
 หรือ <a :class="maincolor" href="tel:0910059903"> โทร.091-005-9903</a>
          เพราะทุกปัญหาเรื่องภาษีใกล้ตัวคุณมากกว่าที่คุณรู้
      </h6>
    </b-card>
      <b-row>
      
        <b-col class="text-center padding" cols="12">
          
          <router-link to="/">
          <b-button variant="warning">กลับ</b-button>&nbsp;&nbsp;&nbsp;
        </router-link>
        <router-link to="/">
          <b-button variant="warning">ทำแบบทดสอบอีกครั้ง</b-button>
        </router-link>
   
        </b-col>
      </b-row>
      <b-row>
      </b-row>
    </div>
  </div>
</template>
<script>


export default {
  
  data() {
    return {
   
       
      maincolor:"maincolor",
      warning:"warning"
    };
  },
  
 
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.maincolor{
color:#EA6B0D;
}
.warning{
  color: #EA6B0D;
}

.padding{
  padding-bottom:20px;
  padding-top: 20px;
}
.btn-warning {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
  }

    .btn-warning:hover {
    color: #EA6B0D;
    background-color: #ffffff;
    border-color: #EA6B0D;
  } 
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
  }
  .btn-warning:focus, .btn-warning.focus {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
    box-shadow: 0 0 0 0.2rem rgba(239, 166, 20, 0.5);
  }
  .btn-secondary:hover {
    color: #5a6268;
    background-color: #ffffff;
    border-color: #545b62;
}
a:hover {
    color: #0056b3;
    text-decoration: none;
}

</style>
