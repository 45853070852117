<template>
  <div class="PartnershipView">
    <ThePartnershipComponent />
  </div>
</template>
<script>
// @ is an alias to /src
import ThePartnershipComponent from "@/components/ThePartnershipComponent.vue";

export default {
  name: "PartnershipView",
  components: {
    ThePartnershipComponent,
  },
};
</script>
