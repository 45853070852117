<template>
  <div class="LimitedView">
    <TheLimitedComponent />
  </div>
</template>

<script>
import TheLimitedComponent from "@/components/TheLimitedComponent.vue";

export default {
  name: "LimitedView",
  components: {
    TheLimitedComponent,
  },
};
</script>
