import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LimitedView from "../views/LimitedView.vue";
import PartnershipView from "../views/PartnershipView";
import TheAssessmentFormCard from "../components/TheAssessmentFormCard.vue";
import OrdinaryView from "../views/OrdinaryView.vue";

Vue.use(VueRouter);



const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/LimitedView",
    component: LimitedView,
  },
  {
    path: "/PartnershipView",
    component: PartnershipView,
  },
  {
    path:"/OrdinaryView",
    component:OrdinaryView,
  },
  {
    path: "/assessment",
    name: "assessment",
    component: TheAssessmentFormCard,
  },

  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
