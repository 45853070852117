<template>
  <div class="OrdinaryView">
    <TheOrdinaryComponent />
  </div>
</template>
<script>
// @ is an alias to /src
import TheOrdinaryComponent from "@/components/TheOrdinaryComponent.vue";

export default {
  name: "OrdinaryView",
  components: {
    TheOrdinaryComponent,
  },
};
</script>
